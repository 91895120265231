import { getStorage } from "../utils/storage";
import { getDeviceProperties, validateEvent } from "@velocity/tracking";

export const surveyValueChange = (dispatch, survey) => {
  const data = {
    survey,
    studyId: getStorage("encrypted_study_id"),
    patientRefId: getStorage("refId"),
  };

  const event = {
    event_type: "survey_value_change",
    time_stamp: new Date().toISOString(),

    agent_properties: getDeviceProperties(),
    event_properties: {
      question_id: data.survey.currentPage.id,
      question_type: data.survey.currentPage.questions[0].getType(),
      question_title: data.survey.currentPage.questions[0].title,
      question_description: data.survey.currentPage.questions[0].description,
      question_value: data.survey.currentPage.questions[0].value,
      question_visible: data.survey.currentPage.questions[0].visible,
      question_visibleIf: data.survey.currentPage.questions[0].visibleIf,
      question_visibleIndex: data.survey.currentPage.questions[0].visibleIndex,
      question_isRequired: data.survey.currentPage.questions[0].isRequired,
      question_isRequiredErrorText:
        data.survey.currentPage.questions[0].isRequiredErrorText,
      question_hasComment: data.survey.currentPage.questions[0].hasComment,
      question_hasOther: data.survey.currentPage.questions[0].hasOther,
      question_hasSelectAll: data.survey.currentPage.questions[0].hasSelectAll,
      question_hasNone: data.survey.currentPage.questions[0].hasNone,
    },
    custom_properties: {
      studyId: data.studyId,
      patientRefId: data.patientRefId,
    },
  };

  validateEvent(event);

  dispatch(event);
};

export const surveyStart = (dispatch) => {
  const data = {
    studyId: getStorage("encrypted_study_id"),
    patientRefId: getStorage("refId"),
  };

  const event = {
    event_type: "survey_started",
    time_stamp: new Date().toISOString(),

    agent_properties: getDeviceProperties(),
    event_properties: {
      survey_id: data.survey_id,
      time: new Date().toISOString(),
    },
    custom_properties: {
      studyId: data.studyId,
      patientRefId: data.patientRefId,
    },
  };
  validateEvent(event);

  dispatch(event);
};
export const surveyCompleted = (dispatch) => {
  const data = {
    studyId: getStorage("encrypted_study_id"),
    patientRefId: getStorage("refId"),
  };

  const event = {
    event_type: "survey_completed",
    time_stamp: new Date().toISOString(),

    agent_properties: getDeviceProperties(),
    event_properties: {
      survey_id: data.survey_id,
      time: new Date().toISOString(),
    },
    custom_properties: {
      studyId: data.studyId,
      patientRefId: data.patientRefId,
    },
  };

  validateEvent(event);
  dispatch(event);
};
export const surveyQuestionChange = (dispatch, survey) => {
  const data = {
    survey,

    studyId: getStorage("encrypted_study_id"),
    patientRefId: getStorage("refId"),
  };
  const event = {
    event_type: "survey_question_change",
    time_stamp: new Date().toISOString(),

    agent_properties: getDeviceProperties(),

    event_properties: {
      question_id: data.survey.currentPage.id,
      question_type: data.survey.currentPage.questions[0].getType(),
      question_title: data.survey.currentPage.questions[0].title,
      question_description: data.survey.currentPage.questions[0].description,
      question_value: data.survey.currentPage.questions[0].value,
      question_visible: data.survey.currentPage.questions[0].visible,
      question_visibleIf: data.survey.currentPage.questions[0].visibleIf,
      question_visibleIndex: data.survey.currentPage.questions[0].visibleIndex,
      question_isRequired: data.survey.currentPage.questions[0].isRequired,
      question_isRequiredErrorText:
        data.survey.currentPage.questions[0].isRequiredErrorText,
      question_hasComment: data.survey.currentPage.questions[0].hasComment,
      question_hasOther: data.survey.currentPage.questions[0].hasOther,
      question_hasSelectAll: data.survey.currentPage.questions[0].hasSelectAll,
      question_hasNone: data.survey.currentPage.questions[0].hasNone,
    },
    custom_properties: {
      studyId: data.studyId,
      patientRefId: data.patientRefId,
    },
  };
  validateEvent(event);

  dispatch(event);
};

// istanbul ignore next
export const surveyError = (dispatch, survey) => {
  const data = {
    survey,
    studyId: getStorage("encrypted_study_id"),
    patientRefId: getStorage("refId"),
  };
  const event = {
    event_type: "survey_error",
    time_stamp: new Date().toISOString(),
    user_id: data.user_id,
    agent_properties: getDeviceProperties(),
    event_properties: {
      errors: data.survey.currentPage.questions[0].errors,
      question_id: data.survey.currentPage.id,
      question_type: data.survey.currentPage.questions[0].getType(),
      question_title: data.survey.currentPage.questions[0].title,
      question_description: data.survey.currentPage.questions[0].description,
      question_value: data.survey.currentPage.questions[0].value,
      question_visible: data.survey.currentPage.questions[0].visible,
      question_visibleIf: data.survey.currentPage.questions[0].visibleIf,
      question_visibleIndex: data.survey.currentPage.questions[0].visibleIndex,
      question_isRequired: data.survey.currentPage.questions[0].isRequired,
      question_isRequiredErrorText:
        data.survey.currentPage.questions[0].isRequiredErrorText,
      question_hasComment: data.survey.currentPage.questions[0].hasComment,
      question_hasOther: data.survey.currentPage.questions[0].hasOther,
      question_hasSelectAll: data.survey.currentPage.questions[0].hasSelectAll,
      question_hasNone: data.survey.currentPage.questions[0].hasNone,
    },
    custom_properties: {
      user_id: data.user_id,
      survey_id: data.survey_id,
    },
  };
  validateEvent(event);

  dispatch(event);
};
