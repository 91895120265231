import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCampaignAPI } from "../api/campaign";
import LoadingIndicator from "../components/loadingIndicator";
import styles from "../styles/start.module.css";
import { setStorage } from "../utils/storage";

const Start = () => {
  const navigation = useNavigate();

  const result = window.location.href.split("?");
  const result_string = result[1].split("&");
  const studyId = result_string[0].split("study_id=")[1];

  const load = async () => {
    const data = await getCampaignAPI(studyId);

    setStorage("encrypted_study_id", studyId);
    data.is_campaign_active &&
      setStorage("is_campaign_active", data.is_campaign_active);

    data.study_synopsis && setStorage("study_synopsis", data.study_synopsis);

    data.survey_json && setStorage("survey_json", data.survey_json);
    data.encrypted_survey_id &&
      setStorage("encrypted_survey_id", data.encrypted_survey_id);

    if (data?.is_campaign_active) {
      navigation("/terms");
    } else {
      window.open("https://velocityclinicaltrials.com/find-study/", "_self");
    }
  };

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  return (
    <div className={styles.body}>
      <LoadingIndicator />
      <p className={styles.text}>Please wait!</p>
    </div>
  );
};

export default Start;
