import React from "react";
import styles from "../styles/blank.module.css";
import { useTracking } from "@velocity/tracking";
import { getStorage } from "../utils/storage";
const Blank = () => {
  useTracking({
    custom_properties: {
      studyId: getStorage("encrypted_study_id"),
    },
  });

  return (
    <div data-testid="blank">
      <div className={styles.card}>
        <div className={styles.innerCard}>
          <p className={styles.typography}>
            <span className={styles.span}>
              {sessionStorage.getItem("surveySubmitMessage")}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Blank;
