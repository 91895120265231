import React from "react";
import { Button } from "semantic-ui-react";
import { SchedulingURL } from "../config/config";
import styles from "../styles/thankyou.module.css";
import { getStorage } from "../utils/storage";
import { useTracking } from "@velocity/tracking";

export const ThankYouPage = () => {
  const siteId = getStorage("encrypted_ctms_site_id"),
    studyId = getStorage("encrypted_ctms_study_id"),
    ctmsPatientId = getStorage("encrypted_ctms_patient_id"),
    prescreeningStatus = getStorage("prescreening_status") === "pass",
    schedulingStatus = getStorage("self_scheduling"),
    enrollmentSuccess = getStorage("enrollment_success"),
    vcPatientId = getStorage("encrypted_vc_patient_id"),
    surveyId = getStorage("encrypted_survey_id"),
    vaccineDate = getStorage("vaccineDate");
  const isVaccineDateDefined = !(
    vaccineDate === "undefined" ||
    vaccineDate === undefined ||
    vaccineDate === null
  );

  useTracking({
    custom_properties: {
      studyId: getStorage("encrypted_study_id"),
      ctmsStudyId: studyId,
      ctmsPatientId,
      vcPatientId,
      siteId,
      surveyId,
      prescreeningStatus,
      schedulingStatus,
      enrollmentSuccess,
    },
  });

  let url = `${SchedulingURL}/?survey_id=${surveyId}&patient_id=${vcPatientId}&study_id=${studyId}&site_id=${siteId}&ctms_patient_id=${ctmsPatientId}`;
  url = url + (isVaccineDateDefined ? `&vaccine_date=${vaccineDate}` : "");

  const showPrescreenStatusMessage = (
    prescreeningStatus,
    schedulingStatus,
    enrollmentSuccess
  ) => {
    if (prescreeningStatus) {
      // if (schedulingStatus && enrollmentSuccess) {
      //   return (
      // <div data-testid="schedule-message">
      //   <p className={styles.heading}> Congratulations !</p>
      //   <p className={styles.typography}>
      //     Based on the answers provided you might be a good fit to
      //     participate in the study. The next step is to schedule a visit to
      //     our clinic.
      //   </p>
      // </div>
      //   );
      // } else {
      //   return (
      //     <div data-testid="no-schedule-message">
      //       <p className={styles.heading}> Congratulations!</p>
      //       <p className={styles.typography}>
      //         Based on the answers provided you might be a good fit to
      //         participate in the study. You will receive a call from one of our
      //         site recruiters to schedule your site visit.
      //       </p>
      //     </div>
      //   );
      // }
    } else {
      return (
        <div data-testid="prescreen-fail-message">
          <p className={styles.heading}>
            <strong>Thank you for your time.</strong>
          </p>
          <p className={styles.typography}>
            Based on the answers you provided it looks like you are not
            currently eligible for this study. If you would like to learn more
            about other clinical trials that may be a better fit, Please contact
            us at &nbsp;{" "}
            <a href="https://velocitypatients.com/contact/">
              https://velocitypatients.com/contact/
            </a>{" "}
            <br></br> <br></br>
            We will continue to reach out when we think we may have a study that
            may be a good fit.
          </p>
        </div>
      );
    }
  };

  return (
    <div className={styles.page}>
      <div className={styles.container}>
        <div className={styles.content}>
          {/* {showPrescreenStatusMessage(
            prescreeningStatus,
            schedulingStatus,
            enrollmentSuccess
          )} */}

          <div data-testid="schedule-message">
            <p className={styles.typography}>
              Thank you for taking the time to update your information. Your
              details have been successfully submitted, and we appreciate your
              commitment to staying informed about clinical trials at Velocity
              Clinical Research. Our team will now be better equipped to notify
              you about relevant studies as they become available.<br></br> If
              you have any questions or need further assistance, please contact
              us at info@velocityclinicaltrials.com.
            </p>
          </div>
        </div>

        {/* {prescreeningStatus && schedulingStatus && enrollmentSuccess ? (
          <Button style={Styles.Button}>
            <a
              href={url}
              style={{
                color: "white",
              }}
            >
              Schedule&nbsp;Appointment
            </a>
          </Button>
        ) : null} */}
      </div>
    </div>
  );
};

const Styles = {
  Button: {
    textTransform: "none",
    letterSpacing: 1,
    fontSize: "20px",
    height: " 60px",
    fontFamily: "Avenir",
    backgroundColor: "#294199",
    padding: "16px 64px",
    margin: "10px 0 0 30px",
    color: "#f8f8f8",
    fontWeight: "700",
    alignSelf: "flex-end",
  },
};

// Media Query
if (window.matchMedia("(max-width: 767px)").matches) {
  Styles.Button.padding = "8px 32px"; // Modify the margin property for mobile view
  // Modify the margin property for mobile view
}
export default ThankYouPage;
