import { domainName, platformAPI } from "../config/config";

export const getCampaignAPI = async (study_id) => {
  const result = await fetch(`${domainName}campaigns/${study_id}`);
  let getCampaignAPIResponse = await result.json();
  return getCampaignAPIResponse;
};

export const registerPatientAPI = async (data) => {
  const result = await fetch(`${platformAPI}patients`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "x-source": "campaign",
    },
    body: JSON.stringify(data),
  });
  let registerPatientAPIResponse = await result.json();
  return registerPatientAPIResponse;
};

export const submitCampaignAPI = async (encryptedStudyId, data) => {
  const result = await fetch(`${domainName}campaigns/${encryptedStudyId}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  let submitCampaignAPIResponse = await result.json();
  return submitCampaignAPIResponse;
};
